import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiPostableSubject, ApiPublicProfile, ApiUser } from '@tytapp/api';
import { ActivitiesApi } from '@tytapp/api/api/ActivitiesApi';
import { ApiActivity } from '@tytapp/api/model/Activity';
import { Shell } from '@tytapp/common';

@Component({
    selector: 'tyt-activities-feed',
    templateUrl: './activities-feed.component.html',
    styleUrls: ['./activities-feed.component.scss']
})
export class ActivitiesFeedComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private activitiesApi: ActivitiesApi,
        private shell: Shell,
        private ngZone: NgZone
    ) { }

    state: 'loading' | 'loaded' = 'loading';

    @Input() profile: ApiPublicProfile;
    @Input() user: ApiUser;

    userActivities: ApiActivity[] = [];

    get activityCount() {
        return this.profile.activities_count;
    }

    page = 1;

    @ViewChild('feedContainerEnd') feedContainerEnd: ElementRef;

    observer: IntersectionObserver;
    intersectDebounce = false;
    intersectDebounceTimer;

    ngOnInit(): void {
        this.loadActivities();
    }

    ngOnDestroy(): void {
        this.observer?.disconnect();
    }

    ngAfterViewInit(): void {
        this.createObserver();
    }

    hasMore = true;

    async loadActivities() {
        if (!this.hasMore)
            return;

        const pageActivities = await this.activitiesApi.list(
            this.profile.username,
            this.page
        ).toPromise();

        this.userActivities.push(...pageActivities);
        this.state = 'loaded';

        if (this.page === 1) {
            this.hasMore = this.activityCount > this.userActivities.length;
        }

        if (pageActivities.length === 0) {
            this.hasMore = false;
            return;
        }

        this.page += 1;
    }

    get isSelf() {
        if (!this.user || !this.profile)
            return false;

        return this.user.username === this.profile.username;
    }

    createObserver() {
        this.ngZone.runOutsideAngular(() => {
            this.observer = new IntersectionObserver(async entries => {
                if (this.state !== 'loaded' || this.intersectDebounce)
                    return;

                clearTimeout(this.intersectDebounceTimer);
                let entry = entries[entries.length - 1];
                if (entry.isIntersecting) {
                    this.ngZone.runGuarded(() => {
                        this.intersectDebounce = true;
                        try {
                            this.loadActivities();
                        } finally {
                            this.intersectDebounceTimer = setTimeout(() => {
                                this.intersectDebounce = false;
                            }, 100);
                        }
                    })
                }
            }, {
                rootMargin: '0px 0px 100px 0px',
            });
        });

        this.observer.observe(this.feedContainerEnd.nativeElement);
    }

    formatVerb(verb: string) {
        if (verb === 'voted')
            return 'voted in';
        return verb;
    }

    urlForSubject(subject: ApiPostableSubject) {
        return this.shell.urlForSubject(subject);
    }

    prefixForSubject(subject: ApiPostableSubject) {
        if (subject.type === 'CMS::Show')
            return 'the show';
        else if (subject.type === 'CMS::Topic')
            return 'the topic';
        else if (subject.type === 'ChooseOnePoll')
            return 'the poll';
        else if (subject.type === 'Petition')
            return 'the petition';

        return '';
    }
}

@if (state === 'loading') {
  <app-spinner class="initial-loading"></app-spinner>
}
@if (state === 'loaded') {
  @if (isSelf) {
    <div class="visibility-information">
      This information is set to {{profile?.privacy_settings.activity_visibility | uppercase}}. You may edit this in <a
    routerLink="/settings/privacy-controls">privacy settings</a>
  </div>
}
<div class="counter-row">
  <div class="counter">{{activityCount | shortNumber}}</div>
  <label>Total Activities</label>
</div>
@if (activityCount === 0) {
  <div class="empty-message">
    {{isSelf ? 'You have no activity yet!' : 'This person has not activity yet.'}}
  </div>
}
@for (activity of userActivities; track activity) {
  <div class="activity">
    <img class="avatar" [src]="activity.subject.avatar_url ?? '/assets/new-avatar.png'" alt="">
    <div class="content">
      <div class="verb">{{activity.object?.name ?? activity.verb}}</div>
      <div class="subject">
        <a routerLink="/@{{activity.user.username}}" class="username">&#64;{{activity.user.username}}</a>
        {{formatVerb(activity.verb)}}
        {{prefixForSubject(activity.subject)}}
        <a [routerLink]="urlForSubject(activity.subject)" class="subject-link">{{activity.subject.name}}</a>
      </div>
    </div>
  </div>
}
}

<div #feedContainerEnd>
</div>

@if (hasMore) {
  <button mat-button (click)="loadActivities()">More</button>
}

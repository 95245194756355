import { Injectable, inject } from '@angular/core';
import { ApiBulkSubjectsQueryBooleanResult, FollowApi, LikesApi } from '@tytapp/api';
import { LoggerService } from '@tytapp/common';
import { UserService } from '@tytapp/user';
import { Subject } from 'rxjs';

interface LikeLookupRequest {
    type: string;
    id: string;
    resolve: (value: boolean) => void;
    promise: Promise<boolean>;
}

interface LikeStateEvent {
    type: string;
    id: string;
    liked: boolean;
}

@Injectable()
export class LikesService {
    private likesApi = inject(LikesApi);
    private userService = inject(UserService);
    private logger = inject(LoggerService);

    constructor() {
        this.userService.userChanged.subscribe(user => {
            let newUserID = user?.id;
            if (this.userID !== newUserID) {
                this.userID = newUserID;
                this.map = new Map<string, LikeLookupRequest>();
            }
        });
    }

    userID: number;

    map = new Map<string, LikeLookupRequest>();
    queue: LikeLookupRequest[] = [];

    private _events = new Subject<LikeStateEvent>();
    private _events$ = this._events.asObservable();
    get events() { return this._events$; }

    async like(type: string, id: string): Promise<void> {
        await this.userService.ready;
        if (!this.userService.user)
            return;
        await this.likesApi.like(type, id).toPromise();
        let key = `${type}/${id}`;
        this.map.set(key, {
            type, id,
            promise: Promise.resolve(true),
            resolve: () => {}
        });
        this._events.next({ type, id, liked: true });
    }

    async unlike(type: string, id: string): Promise<void> {
        await this.userService.ready;
        if (!this.userService.user)
            return;
        await this.likesApi.unlike(type, id).toPromise();
        let key = `${type}/${id}`;
        this.map.set(key, {
            type, id,
            promise: Promise.resolve(false),
            resolve: () => {}
        });
        this._events.next({ type, id, liked: false });
    }

    async isLiked(type: string, id: string): Promise<boolean> {
        await this.userService.ready;
        if (!this.userService.user)
            return false;
        let key = `${type}/${id}`;
        if (this.map.has(key))
            return this.map.get(key).promise;

        let resolve: (value: boolean) => void;
        let promise = new Promise<boolean>(r => resolve = r);
        this.queue.push({ type, id, promise, resolve });
        promise.then(liked => {
            this._events.next({ type, id, liked });
        });

        this.pump();
        return promise;
    }

    timeout;

    private pump() {
        clearTimeout(this.timeout);
        if (this.queue.length === 0)
            return;

        this.timeout = setTimeout(() => this.fetch(), 500);
    }

    private isFetching = false;
    private async fetch() {
        if (this.isFetching)
            return;

        this.isFetching = true;
        try {
            let chunk = this.queue.splice(0, 200);
            this.logger.info(`[Likes] Batching ${chunk.length} follow status requests...`);
            let results: ApiBulkSubjectsQueryBooleanResult;

            try {
                results = await this.likesApi.queryLikes({
                    subjects: chunk.map(({ id, type })=> ({ id, type }))
                }).toPromise();
            } catch (e) {
                this.logger.error(`Failed to query likes:`);
                this.logger.error(e);
                chunk.forEach((follow, i) => follow.resolve(false));
                return;
            }

            chunk.forEach((follow, i) => follow.resolve(results.subjects[i]));
        } finally {
            this.isFetching = false;
        }

        this.pump();
    }
}
import { Component, inject } from '@angular/core';
import { ApiUser } from '@tytapp/api';
import { DialogComponent, NamedDialog } from '@tytapp/common';
import { UserService } from '@tytapp/user';
import { Subscription } from 'rxjs';

@NamedDialog('commentingDisabled')
@Component({
    template: `
        @if (!user) {
          <app-spinner></app-spinner>
        }
        @if (user) {
          <h1>
            You are
            @if (until) {
              currently
            }
            not allowed to post on TYT.com
          </h1>
          <p>
            Your ability to post content on TYT.com has been
            @if (until) {
              restricted until {{ until | date: 'longDate' }}.
            }
            @if (!until) {
              permanently restricted.
            }
          </p>
          @if (until) {
            <p>Once the restriction is lifted you will be able to post
            content as you had been able to previously.</p>
          }
          <p>Please check your email and/or TYT.com notifications for more
          information about this moderation action.</p>
          <button mat-raised-button color="primary" (click)="close()">
            Close
          </button>
        }
        `,
    styles: [``]
})
export class CommentingDisabledComponent extends DialogComponent {
    private userService = inject(UserService);

    subscriptions = new Subscription();
    user: ApiUser;

    get until() {
        return this.user.disable_commenting_until;
    }

    init() {
        this.subscriptions.add(this.userService.userChanged.subscribe(user => this.user = user));
    }

    destroy() {
        this.subscriptions.unsubscribe();
    }

    close() {
        this.shell.hideDialog();
    }
}
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, HostBinding, inject, Input, Output, ViewChild } from '@angular/core';
import { ApiArticle, ApiComment, ApiCommentAttachment, ApiPost, ApiPostWithReplies, ApiUser, PostsApi } from '@tytapp/api';
import { Shell } from '@tytapp/common';
import { ArticlesService } from '@tytapp/articles';
import { isServerSide } from '@tytapp/environment-utils';
import { ReportComponent } from '@tytapp/moderation';
import { HideAsStaffComponent } from '@tytapp/moderation/hide-as-staff/hide-as-staff.component';
import { UserService } from '@tytapp/user';
import { Subject, Subscription } from 'rxjs';
import { ChatMessageAttachment } from '@banta/common';

@Component({
    selector: 'tyt-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.scss']
})
export class PostComponent {
    private postApi = inject(PostsApi);
    private userService = inject(UserService);
    private overlay = inject(Overlay);
    private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
    private shell = inject(Shell);
    private articlesService = inject(ArticlesService);

    repostEnabled = false;

    @Input()
    showReply = false;
    @Input() showLike = true;

    @Input() compact = false;
    @Input() quickAction: 'like' | 'reply' = null;
    @Output() replyCreated = new Subject<ApiPost>();
    @ViewChild('selectorPanelTemplate') selectorPanelTemplate: TemplatePortal<any>;

    @Input() isUserHome = false;

    get shareTitle() {
        if (!this.post)
            return null;

        if (this.post.comment)
            return this.post.comment.text;
        else if (this.post.poll)
            return this.post.poll.title || this.post.poll.description;
        else if (this.post.production)
            return this.post.production.title;
        else if (this.post.article)
            return this.post.article.title;
    }

    get url() {
        if (!this.post)
            return null;

        if (isServerSide())
            return `/@${this.post.user.username}/posts/${this.post.id}`;
        else
            return `${location.origin}/@${this.post.user.username}/posts/${this.post.id}`;
    }

    get replyPrompt() {
        if (this.post.subject_type === 'Comment')
            return (this.isSelf ? 'Reply to yourself' : 'Reply to @' + this.post.user.username);
        else
            return `Post comment`;
    }

    /**
     * Set to true when this component has the active focus of the user.
     * Some animations are disabled to ensure that absolute/fixed positioning inside this
     * post will be respected (such as miniplayer for media).
     */
    @HostBinding('class.activated')
    @Input() isActivated: boolean;

    @Output() activated = new Subject<void>();
    @Output() deactivated = new Subject<void>();

    @Input() post: ApiPostWithReplies;
    @Input() showReplies = true;
    @Input() showParent: boolean | 'stemmed' = true;
    @Input() stemmed = false;
    @Input() showAsTopLevel = false;

    @Input() threaded = false;

    @HostBinding('class.threaded')
    get showAsThreaded() {
        return this.threaded || (this.showReplies && this.hasReplies);
    }

    @Output() deleted = new Subject<void>();
    @Output() edited = new Subject<ApiPost>();

    currentUser: ApiUser;
    get isSelf() {
        if (!this.post || !this.currentUser)
            return false;
        return this.currentUser.id === this.post.user.id;
    }

    get isStaff() {
        return this.currentUser?.staff;
    }

    subscriptions = new Subscription();

    disableSharing = false;

    async ngOnInit() {
        this.subscriptions.add(this.userService.userChanged.subscribe(user => this.currentUser = user));
        this.disableSharing = await this.shell.hasFeature('apps.web.disable_sharing');
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get profileUrl() {
        if (!this.post)
            return undefined;

        if (this.stemmed)
            return this.postUrl;

        return `/@${this.post.user.username}`;
    }

    get postUrl() {
        if (!this.post)
            return undefined;

        return `/@${this.post.user.username}/posts/${this.post.id}`;
    }

    get headerFormat() {
        if (!this.isPostVisible(this.post))
            return 'as-invisible';

        if (this.compact)
            return 'as-reply';

        if ((this.showParent === false || this.showParent === 'stemmed') && this.post?.parent_id && !this.threaded) {
            return 'as-top-level-reply';
        } else if (this.post?.parent_id) {
            return 'as-reply';
        }

        return 'as-post';
    }

    get hasParent() {
        return !!this.post?.parent_id;
    }

    get subjectType() {
        return this.post?.subject_type;
    }

    get isSponsored() { return false; /* return this.post?.is_sponsored; */ }
    get isPinned() { return false; /*return this.post?.is_pinned; */ }
    get isPromoted() { return false; /* return this.post?.is_promoted; */ }

    get shouldShowTagSpace() {
        if (!this.post)
            return false;

        return this.isPinned || this.isSponsored || this.isPromoted;
    }

    get topicTitle() {
        return (this.post?.topic)?.name;
    }

    get postUserAvatar() {
        return `${this.post?.user?.avatar || '/assets/new-avatar.png'}`;
    }

    get isHighlighted() { return false; /* this.post?.is_highlighted; */ }

    get isTopicComment() {
        return this.post?.comment?.topic_id && this.post?.comment?.topic_id !== 'wallposts';
    }

    get recommendationText(): string { return undefined; /* return this.post?.information_leader_text; */ }

    get topicLink() {
        return this.post?.topic ? `/topics/${this.post.topic.slug}` : undefined;
    }

    attachmentsForComment(comment: ApiComment) {
        return <ChatMessageAttachment[]>comment.attachments;
    }

    get attachments() {
        return this.post?.comment?.attachments || [];
    }

    get moreAttachments() {
        if (this.attachments.length === 1) return null;
        if (this.isTopicComment && this.attachments.length > 1) return this.attachments;
        return this.attachments.slice(1);
    }

    overlayRef: OverlayRef;

    share() {
        const postElement = this.elementRef.nativeElement.querySelector('.post-container');
        if (!postElement)
            return;

        this.overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position()
            .flexibleConnectedTo(postElement)
            .withPositions([{
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top'
            }])
            .withFlexibleDimensions(true),
            hasBackdrop: true,
            disposeOnNavigation: true,
            scrollStrategy: this.overlay.scrollStrategies
            .reposition({autoClose: true})
        });
        this.overlayRef.attach(this.selectorPanelTemplate);
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        })
    }

    async report() {
        this.shell.showDialog(ReportComponent, { subjectType: this.post.subject_type, subjectId: this.post.subject_id });
    }

    async hideAsStaff() {
        this.shell.showDialog(HideAsStaffComponent, { subjectType: this.post.subject_type, subjectId: this.post.subject_id });
    }

    edit() {

    }

    async delete() {
        if (!confirm('Are you sure you want to delete this post?'))
            return;

        await this.postApi.deletePost(this.post.id).toPromise();
        this.deleted.next();
    }

    adjustCounter(counter: string, delta: number) {
        if (!this.post)
            return;

        let count = Number(this.post[counter] ?? 0);
        count += delta;
        count = Math.max(0, count);
        this.post[counter] = count;
    }

    bylineForArticle(article: ApiArticle) {
        if (!article.talent || article.talent.length === 0)
            return '';

        return `By ${article.talent.map(x => x.name).join(', ')}`;
    }

    deactivate() {
        this.isActivated = false;
        this.deactivated.next();
    }

    activate() {
        this.isActivated = true;
        this.activated.next();
    }

    editing = false;
    draftEdit: ApiPost;

    startEditing() {
        this.editing = true;
        this.draftEdit = JSON.parse(JSON.stringify(this.post));
    }

    finishEditing(post: ApiPost) {
        this.editing = false;
        Object.assign(this.post, post);
        this.edited.next(post);
    }

    getContextLink(post: ApiPost) {
        if (post.parent_id)
            return null;
        if (post.comment) {
            if (post.comment.topic_id?.startsWith('tytlive_')) {
                let date = post.comment.topic_id.replace(/^tytlive_/, '').replace(/_comments$/, '');
                return { text: `TYT Live, ${date}`, url: `/spot/${post.comment.topic_id.replace(/_comments$/, '')}` };
            }

            if (post.comment.subject) {
                return { text: post.comment.subject.name, url: this.shell.urlForSubject(post.comment.subject) };
            }
        }
    }

    isPostVisible(post: ApiPost) {
        if (!post)
            return false;
        return !post.deleted_at && !post.hidden;
    }

    get hasReplies() {
        if (!this.post)
            return false;
        return this.post.replies?.length > 0;
    }

    articleUrl(article: ApiArticle) {
        return this.articlesService.getArticleUrlSync(article);
    }
}

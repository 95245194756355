<button
  mat-button
  [disabled]="acting"
  [class.liked]="isLiked"
  [class.unfollow]="isLiked"
  (click)="isLiked ? unlike() : like()"
  >
  <div class="button-content">
    @if (!acting) {
      <mat-icon>thumb_up</mat-icon>
    }
    @if (acting) {
      <mat-icon class="sync">sync</mat-icon>
    }
    <ng-content></ng-content>
  </div>
</button>
import { Component, inject, Input, Output, ViewChild } from '@angular/core';
import {
    FormInput, Validated, MonthValidator, DayOfMonthValidator,
    YearValidator, FieldError, zeroPad, BaseComponent, PageComponent
} from '@tytapp/common';
import { UserService, ImageSaveOperation } from '@tytapp/user';
import { UsersApi, ApiUserProfile, CountriesApi, ApiUser } from '@tytapp/api';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormComponent } from '@tytapp/common/form/form.component';

export interface Country {
    name: string;
    code: string;
}

@Component({
    selector: 'tyt-profile-editor',
    templateUrl: './profile-editor.component.html',
    styleUrls: ['./profile-editor.component.scss'],
})
export class ProfileEditorComponent extends PageComponent {
    private userService = inject(UserService);
    private userApi = inject(UsersApi);
    private countriesApi = inject(CountriesApi);
    private matSnackBar = inject(MatSnackBar);

    smsOptin = this.userService.smsOptin;

    /**
     * TODO: Policy and data security changes are needed before we can enable these.
     */
    enableDemographicsFields = false;

    @FormInput()
    displayName: string = "";

    @FormInput()
    firstName: string = "";

    @FormInput()
    pronouns: string = "";

    @FormInput()
    location: string = "";

    @FormInput()
    lastName: string = "";

    @FormInput()
    address1: string = "";

    @FormInput()
    address2: string = "";

    @FormInput()
    city: string = "";

    @FormInput()
    state: string = "";

    @FormInput()
    zip: string = "";

    @FormInput()
    country: string = "";

    @FormInput()
    username: string = "";

    @FormInput()
    facebookUrl: string = "";

    @FormInput()
    twitterHandle: string = "";

    @FormInput()
    instagramHandle: string = "";

    @FormInput()
    mastodonHandle: string = "";

    @FormInput()
    linkedInHandle: string = "";

    @FormInput()
    disablePublic: boolean = false;

    @Validated({ class: MonthValidator, field: 'month' })
    @FormInput()
    month: string = "";

    @Validated({ class: DayOfMonthValidator, field: 'day' })
    @FormInput()
    day: string = "";

    @Validated({ class: YearValidator, field: 'year' })
    @FormInput()
    year: string = "";

    @FormInput()
    gender: string = "";

    @FormInput()
    race: string = "";

    @FormInput()
    sexuality: string = "";

    @FormInput()
    education: string = "";

    @FormInput()
    income: string = "";

    @FormInput()
    party: string = "";

    @FormInput()
    aboutMe: string = "";

    @FormInput()
    metadata: any = "";

    @FormInput()
    favoriteInspirationalFigure: string = "";

    @FormInput()
    tagline: string = "";

    @FormInput()
    favoriteQuote: string = "";

    @FormInput()
    favoriteTYTMemory: string = "";

    @FormInput()
    politicalViews: string = "";

    @FormInput()
    religionOrPhilosophy: string = "";

    @FormInput()
    phoneNumber: string = "";

    updateSuccessMessage: string = '';
    edit: boolean = false;

    @Input()
    user: ApiUser;

    @Input()
    includeAvatarEditor = false;

    @Output()
    saved = new Subject<void>();

    countries: Country[];

    availablePronouns = [
        { label: 'She/Her', selected: false },
        { label: 'He/Him', selected: false },
        { label: 'They/Them', selected: false },
        { label: 'Ze/Hir', selected: false },
        { label: 'It/It', selected: false },
        { label: 'My name only', selected: false }
    ];

    async init() {
        this.countries = (await this.countriesApi.all().toPromise())
            .map(x => ({ code: x.alpha2, name: x.name }))
            .sort((a, b) => {
                if (a.code === 'US')
                    return -1;

                return a.name.localeCompare(b.name);
            })
            ;

        this.subscribe(this.userService.userChanged, user => {
            this.user = user;
            this.initializeFields();
        });
    }

    async saveAvatar(operation: ImageSaveOperation) {
        let profile: ApiUserProfile;

        try {
            profile = await this.userApi.updateAvatar({ avatar_url: operation.imageUrl }).toPromise();
        } catch (e) {
            if (e.json)
                e = e.json();

            this.logger.error(`Error while uploading avatar image:`);
            this.logger.error(e);
            alert(`Failed to upload image. Please try again.`);
            operation.failed();
            return;
        }

        this.user.profile = profile;
        this.userService.locallyUpdateUser(this.user);

        operation.completed();
    }

    async clearAvatar() {
        if (!confirm(`Are you sure you want to clear your avatar?`))
            return;

        let profile: ApiUserProfile;
        profile = await this.userApi.updateAvatar({ avatar_url: null }).toPromise();

        this.user.profile = profile;
        this.userService.locallyUpdateUser(this.user);
    }

    async saveCover(operation: ImageSaveOperation) {
        let profile: ApiUserProfile;

        try {
            profile = await this.userApi.updateCoverPhoto({ cover_photo_url: operation.imageUrl }).toPromise();
        } catch (e) {
            if (e.json)
                e = e.json();

            this.logger.error(e);
            alert(`Failed to upload image. Please try again.`);
            operation.failed();
            return;
        }

        this.user.profile = profile;
        this.userService.locallyUpdateUser(this.user);

        operation.completed();
    }

    async clearCover() {
        if (!confirm(`Are you sure you want to clear your cover photo?`))
            return;
        let profile: ApiUserProfile;
        profile = await this.userApi.updateCoverPhoto({ cover_photo_url: null }).toPromise();

        this.user.profile = profile;
        this.userService.locallyUpdateUser(this.user);
    }

    avatarStep: string = 'default';
    coverStep: string = 'default';

    get localBirthday() {
        if (!this.user || !this.user.profile || !this.user.profile.birthday)
            return null;

        let birthdate = new Date(this.user.profile.birthday);
        return new Date(birthdate.getTime() + birthdate.getTimezoneOffset() * 60 * 1000);
    }

    initializeFields() {

        if (this.user && this.user.profile) {
            let profile = this.user.profile;

            this.displayName = profile.display_name;
            this.firstName = profile.first_name;
            this.pronouns = profile.pronouns;
            this.location = profile.location;
            this.lastName = profile.last_name;
            // this.address1 = profile.address1;
            // this.address2 = profile.address2;
            this.city = profile.city;
            this.state = profile.state;
            this.zip = profile.zip;
            this.country = profile.country;
            this.username = profile.username;
            this.facebookUrl = profile.facebook_profile;
            this.twitterHandle = profile.twitter_profile;
            this.instagramHandle = profile.instagram_profile;
            this.mastodonHandle = profile.mastodon_handle;
            this.linkedInHandle = profile.linkedin_url;
            this.disablePublic = !profile.is_public;
            this.favoriteInspirationalFigure = profile.favorite_inspirational_figure;
            this.tagline = profile.tagline;
            this.favoriteQuote = profile.favorite_quote;
            this.favoriteTYTMemory = profile.favorite_tyt_memory;
            this.politicalViews = profile.political_views;
            this.religionOrPhilosophy = profile.religion_or_philosophy;
            this.address1 = profile.address1;
            this.address2 = profile.address2;
            this.phoneNumber = '';
            this.phoneNumber = this.user.phone_number; // map to the new phone_number field

            let birthday = this.localBirthday;

            if (birthday) {
                this.month = zeroPad("" + (birthday.getMonth() + 1));
                this.day = zeroPad("" + (birthday.getDate()));

                if (birthday.getFullYear() > 4)
                    this.year = zeroPad("" + (birthday.getFullYear()));
                else
                    this.year = null;
            }
            this.gender = profile.gender;
            //this.race = profile.race;
            //this.sexuality = profile.sexuality;
            //this.education = profile.education;
            //this.income = profile.income;
            // this.party = profile.party
            this.aboutMe = profile.bio;
            this.metadata = profile.metadata || {};
        } else {
            this.displayName = '';
            this.firstName = '';
            this.lastName = '';
            this.pronouns = '';
            this.location = '';
            this.city = '';
            this.address1 = '';
            this.address2 = '';
            this.state = '';
            this.facebookUrl = '';
            this.username = '';
            this.twitterHandle = '';
            this.instagramHandle = '';
            this.mastodonHandle = '';
            this.linkedInHandle = '';
            this.month = '';
            this.day = '';
            this.year = '';
            this.gender = '';
            this.race = '';
            this.sexuality = '';
            this.education = '';
            this.income = '';
            this.party = '';
            this.aboutMe = '';
            this.disablePublic = false;
            this.favoriteInspirationalFigure = '';
            this.tagline = '';
            this.favoriteQuote = '';
            this.favoriteTYTMemory = '';
            this.politicalViews = '';
            this.religionOrPhilosophy = '';
            this.phoneNumber = '';
        }
    }

    submitting = false;

    @ViewChild('form')
    form: FormComponent;

    sanitizeTwitterHandle() {
        if (!this.twitterHandle)
            return;

        setTimeout(() => {
            if (!this.twitterHandle) // See Instagram handle for why this check is necessary
                return;

            this.twitterHandle = this.twitterHandle.trim();
            let urlMatch = this.twitterHandle.match(/https?:\/\/(www\.)?twitter\.com\/@?([^\/]+)/);
            if (urlMatch) {
                this.twitterHandle = urlMatch[2];
            }

            this.twitterHandle = this.twitterHandle.replace(/^@+/, '');
        })
    }

    sanitizeInstagramHandle() {
        if (!this.instagramHandle)
            return;

        setTimeout(() => {
            // On browsers where real-time updates are not available on text fields (seemingingly iOS Safari/WebView)
            // using Discard (which could set instagramHandle to null), may ause the previous instagramHandle check to
            // succeed, but this time delayed action to fail since instagramHandle has been reverted to null.
            if (!this.instagramHandle)
                return;

            this.instagramHandle = this.instagramHandle.trim();
            let urlMatch = this.instagramHandle.match(/https?:\/\/(www\.)?instagram\.com\/@([^\/]+)(\/?\??.*)?/);
            if (urlMatch) {
                this.instagramHandle = urlMatch[2];
            }

            this.instagramHandle = this.instagramHandle.replace(/^@+/, '');
        })
    }

    sanitizeMastodonHandle() {
        if (!this.mastodonHandle)
            return;

        setTimeout(() => {
            if (!this.mastodonHandle) // See Instagram handle for why this check is necessary
                return;

            this.mastodonHandle = this.mastodonHandle.trim();
            let urlMatch = this.mastodonHandle.match(/https?:\/\/([^\/]+)\/@?([^\/]+)(\/?\??.*)?/);
            if (urlMatch) {
                this.mastodonHandle = `@${urlMatch[2]}@${urlMatch[1]}`;
            }
        })
    }

    sanitizePhoneNumber(phoneNumber: string): string {
        return phoneNumber.replace(/[^0-9]/g, '');
    }

    async submit() {
        if (!this.form.validate()) {
            this.notify('Please correct the errors above.', true);
            return;
        }

        if (this.submitting)
            return;
        this.submitting = true;

        this.form.clearErrors();

        try {
            const editFieldData = {
                display_name: this.displayName,
                first_name: this.firstName,
                last_name: this.lastName,
                location: this.location,
                pronouns: this.pronouns,
                address1: this.address1,
                address2: this.address2,
                city: this.city,
                state: this.state ? this.state.toUpperCase() : null,
                facebook_profile: this.facebookUrl,
                twitter_profile: this.twitterHandle,
                instagram_profile: this.instagramHandle,
                linkedin_url: this.linkedInHandle,
                mastodon_handle: this.mastodonHandle,
                username: this.username,
                gender: this.gender,
                race: this.race,
                sexuality: this.sexuality,
                education: this.education,
                income: this.income,
                party: this.party,
                birthday: null,
                bio: this.aboutMe,
                metadata: this.metadata,
                country: this.country,
                zip: this.zip,
                updateAvatar: false,
                is_public: !this.disablePublic,
                tagline: this.tagline,
                favorite_inspirational_figure: this.favoriteInspirationalFigure,
                favorite_quote: this.favoriteQuote,
                favorite_tyt_memory: this.favoriteTYTMemory,
                political_views: this.politicalViews,
                religion_or_philosophy: this.religionOrPhilosophy
            };

            const validPhoneNumberPattern = /^[+]?[0-9\s\-()]*$/;
            if (this.phoneNumber && !validPhoneNumberPattern.test(this.phoneNumber)) {
                this.form.addFieldError('phoneNumber', 'Invalid phone number format');
            }

            if (this.month || this.day) {

                let fieldErrors: FieldError[] = [];

                if (!this.month)
                    fieldErrors.push({ field: 'month', message: '(1 - 12)' });
                if (!this.day)
                    fieldErrors.push({ field: 'day', message: '(1 - 31)' });

                if (!this.month || !this.day) {
                    this.form.showError('To save your birthday we need both month and day. Year is optional.', fieldErrors);
                    return;
                }

                let year = this.year || '0004';
                editFieldData.birthday = `${year}-${this.month}-${this.day}`;
            }

            if (editFieldData.display_name && editFieldData.display_name.length > 50) {
                this.form.addFieldError(`displayName`, `Your display name is too long`);
            }

            if (this.mastodonHandle && !/^@.*@.*\..*/.test(this.mastodonHandle)) {
                this.form.addFieldError('mastodonHandle', `Mastodon handles are in the form @username@domain`);
            }

            if (this.facebookUrl && !/^https:\/\/(www\.)?facebook\.com\/[^\/]+\/?$/.test(this.facebookUrl)) {
                this.form.addFieldError('facebookUrl', `Not a valid Facebook URL`);
            }

            if (this.instagramHandle && !/^[A-Za-z0-9_]+$/.test(this.instagramHandle)) {
                this.form.addFieldError(`instagramHandle`, `Not a valid Instagram handle`);
            }

            if (this.linkedInHandle && !/^https?:\/\/(www\.)?linkedin\.com\/in\//.test(this.linkedInHandle)) {
                this.form.addFieldError(`linkedInHandle`, `Not a valid LinkedIn URL`);
            }

            if (this.twitterHandle && !/^[A-Za-z0-9_]+$/.test(this.twitterHandle)) {
                this.form.addFieldError(`twitterHandle`, `Not a valid Twitter handle`);
            }

            if (this.form.hasErrors) {
                this.form.showError(`Please correct the errors above.`);
                return;
            }

            // UPDATE PROFILE

            try {
                await this.userApi.updateProfile(editFieldData).toPromise();
            } catch (e) {
                if (e.json)
                    e = e.json();
                this.notify(`Could not save your profile: ${e.message || e.error}`, true)

                if (e.code === 'display_name_invalid') {
                    this.form.addFieldError('displayName', e.message || 'The display name you selected is not available')
                }

                return;
            }

            // UPDATE PHONE

            if (this.user.phone_number !== this.phoneNumber) {
                try {
                    await this.userApi.updatePhoneNumber({ phone_number: this.sanitizePhoneNumber(this.phoneNumber) }).toPromise();
                } catch (e) {
                    if (e.json)
                        e = e.json();

                    this.notify(`Your profile is saved, but we couldn't update your phone number: ${e.message || e.error}`, true);
                    this.form.addFieldError('phoneNumber', e.message || e.error || `Could not update your phone number, please try again.`);

                    return;
                }
            }

            this.userService.userChanged.next(await this.userApi.getCurrentUser().toPromise());

            this.edit = false;

            this.notify('Your profile information has been saved.', false);
            this.saved.next();
        } finally {
            this.submitting = false;
        }
    }

    private notify(message: string, isError = false) {
        this.matSnackBar.open(message, undefined, { duration: 5000, panelClass: isError ? 'error' : '' });
    }

    private capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    cancelEdit() {
        this.initializeFields();
        this.edit = false;
        this.notify(`Your changes have been reset.`, false);
    }
}

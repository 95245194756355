@if (poll) {
  <div class="poll_card" [class]="type">
    <picture [responsiveBackgroundImage]="poll.image"></picture>
    <a role="button" class="neutral" routerLink="/{{ route }}">
      <mat-icon>open_in_browser</mat-icon>
      View All {{ type }}s
    </a>
    <h2>Featured {{ type }}</h2>
    <a routerLink="/{{ route }}/{{ poll.slug || poll.id }}">{{ poll.title }}</a>
    <a role="button" class="neutral alt" routerLink="/{{ route }}/{{ poll.slug || poll.id }}">
      {{ type == 'Petition' ? 'Sign' : 'Vote' }}
    </a>
    <div>
      <span>{{ type }} open</span><span>Ends {{ poll.closes_at | date:'longDate' }}</span>
    </div>
    <div>
      {{ poll.opens_at | date:'longDate' }}
    </div>
  </div>
  <div class="poll_buttons">
    <a role="button" class="neutral" routerLink="/{{ route }}/{{ poll.slug || poll.id }}">{{ type }}</a>
    <a role="button" class="neutral">Trade Agreements</a>
  </div>
} @else {
  <app-spinner></app-spinner>
}


@if (state === 'loading' || state === 'loaded') {
  @if (showFilters) {
    <div class="filters">
      @for (filter of postTypeFilters; track filter) {
        <button [class.active]="postTypeFilter === filter.id"
          (click)="setFilter(filter.id)"
        mat-button>{{filter.label}}</button>
      }
    </div>
  }
  @if (showHeader) {
    <div class="header">
      <img src="/assets/social-icons/feed.png" alt="">
      <div class="label">
        {{headerLabel}}
      </div>
      @if (showFilters) {
        @if (showUserFilter) {
          <mat-select
            name="userTypeFilter"
            [(ngModel)]="userTypeFilter"
            (selectionChange)="updateFilter('userType')"
            class="capitalize"
            >
            @for (filter of userTypeFilters; track filter) {
              <mat-option [value]="filter.key">
                {{ filter.label }}
              </mat-option>
            }
          </mat-select>
        }
        @if (showTypeFilter) {
          <mat-select
            name="postTypeFilter"
            [(ngModel)]="postTypeFilter"
            (selectionChange)="updateFilter('postType')"
            class="capitalize"
            >
            @for (filter of postTypeFilters; track filter) {
              <mat-option [value]="filter.key">
                {{ filter.label }}
              </mat-option>
            }
          </mat-select>
        }
      }
    </div>
  }
  @if (hintMessage) {
    <div class="subheader">
      @if (hintIcon) {
        <mat-icon>{{ hintIcon }}</mat-icon>
      }
      {{ hintMessage }}
    </div>
  }
}

@if (state === 'loading') {
  <app-spinner class="initial-loading"></app-spinner>
}
@if (state === 'loaded') {
  @if (posts?.length === 0) {
    <div class="empty">{{emptyMessage}}</div>
  }
  @if (posts.length > 0) {
    @for (post of posts; track post) {
      <tyt-post
        [post]="post"
        [threaded]="false"
        [isActivated]="post['$transient']?.activated"
        (deleted)="onPostDeleted(post)"
        (activated)="activatePost(post)"
        [showReply]="post['$transient']?.activated"
        [isUserHome]="isUserHome"
      ></tyt-post>
    }
  }
  @if (loadingMore) {
    <app-spinner></app-spinner>
  }
}

<div #feedContainerEnd>
</div>

@if (slug === ':community' && !hasMore) {
  @if (posts?.length > 0) {
    <div class="end-of-feed">
      You've reached the end of your feed. Here's some recommended content.
    </div>
  }
  <tyt-post-feed
    slug=":home"
    [showHeader]="false"
  [showFilters]="false"></tyt-post-feed>
}

@if (state === 'private') {
  <div class="error-state">
    <h1>This content is private</h1>
    <p>The user's posts are private.</p>
  </div>
}
@if (state === 'requires-login') {
  <div class="error-state">
    <h1>Sign in to view this content</h1>
    <p>You must sign in to view this content.</p>
  </div>
}
@if (state === 'error') {
  <div class="error-state">
    <h1>That wasn't supposed to happen.</h1>
    <p>We're having trouble loading this feed right now. Please try again later.
    If the issue persists, please contact support.</p>
  </div>
}

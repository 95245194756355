import { inject, Injectable } from '@angular/core';
import { ApiBulkSubjectsQueryBooleanResult, FollowApi } from '@tytapp/api';
import { ClientPersistenceService } from '@tytapp/app/client-persistence.service';
import { LoggerService } from '@tytapp/common';
import { UserService } from '@tytapp/user';
import { Subject } from 'rxjs';

interface FollowLookupRequest {
    type: string;
    id: string;
    resolve: (value: boolean) => void;
    promise: Promise<boolean>;
}

interface FollowStateEvent {
    type: string;
    id: string;
    followed: boolean;
}

@Injectable()
export class FollowsService {
    private followApi = inject(FollowApi);
    private userService = inject(UserService);
    private persistence = inject(ClientPersistenceService);
    private logger = inject(LoggerService);

    constructor() {
        this.userService.userChanged.subscribe(user => {
            let newUserID = user?.id;
            if (this.userID !== newUserID) {
                this.userID = newUserID;
                this.map = new Map<string, FollowLookupRequest>();
            }
        });
    }

    userID: number;
    map = new Map<string, FollowLookupRequest>();
    queue: FollowLookupRequest[] = [];

    private _events = new Subject<FollowStateEvent>();
    private _events$ = this._events.asObservable();
    get events() { return this._events$; }

    async getFollowedShows() {
        return this.persistence.fetchWhenOnline('followed-shows', () => this.followApi.getFollowedShows().toPromise());
    }

    async follow(type: string, id: string): Promise<void> {
        await this.userService.ready;
        if (!this.userService.user)
            return;
        await this.followApi.followEntity(type, id).toPromise();
        let key = `${type}/${id}`;
        this.map.set(key, {
            type, id,
            promise: Promise.resolve(true),
            resolve: () => {}
        });
        this._events.next({ type, id, followed: true });
    }

    async unfollow(type: string, id: string): Promise<void> {
        await this.userService.ready;
        if (!this.userService.user)
            return;
        await this.followApi.unfollowEntity(type, id).toPromise();
        let key = `${type}/${id}`;
        this.map.set(key, {
            type, id,
            promise: Promise.resolve(false),
            resolve: () => {}
        });
        this._events.next({ type, id, followed: false });
    }

    async isFollowing(type: string, id: string): Promise<boolean> {
        await this.userService.ready;
        if (!this.userService.user)
            return;

        let key = `${type}/${id}`;
        if (this.map.has(key))
            return this.map.get(key).promise;

        let resolve: (value: boolean) => void;
        let promise = new Promise<boolean>(r => resolve = r);
        this.queue.push({ type, id, promise, resolve });
        promise.then(followed => {
            this._events.next({ type, id, followed });
        });

        this.pump();
        return promise;
    }

    timeout;

    private pump() {
        clearTimeout(this.timeout);
        if (this.queue.length === 0)
            return;

        this.timeout = setTimeout(() => this.fetch(), 500);
    }

    private isFetching = false;
    private async fetch() {
        if (this.isFetching)
            return;

        this.isFetching = true;
        try {
            let chunk = this.queue.splice(0, 200);
            this.logger.info(`[Follows] Batching ${chunk.length} follow status requests...`);
            let results: ApiBulkSubjectsQueryBooleanResult;

            try {
                results = await this.followApi.queryFollows({
                    subjects: chunk.map(({ id, type })=> ({ id, type }))
                }).toPromise();
            } catch (e) {
                this.logger.error(`Failed to query follows:`);
                this.logger.error(e);
                chunk.forEach((follow, i) => follow.resolve(false));
                return;
            }

            chunk.forEach((follow, i) => follow.resolve(results.subjects[i]));
        } finally {
            this.isFetching = false;
        }

        this.pump();
    }
}
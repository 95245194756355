@if (acting) {
  <button mat-button disabled (click)="follow()">
    <app-spinner class="text-inline" [inline]="true"></app-spinner>
    {{actingLabel}}
  </button>
}
@if (!acting) {
  <button mat-button [class.follow]="!isFollowed" [class.unfollow]="isFollowed" (click)="isFollowed ? unfollow() : follow()">
    <tyt-follow-icon
      [type]="type"
      [followed]="!isFollowed"
      [action]="true"
    ></tyt-follow-icon>
    {{ isFollowed ? unfollowText : followText }}
  </button>
}
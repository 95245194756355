import { Component, Input } from '@angular/core';
import { ApiPost } from '@tytapp/api';

@Component({
    selector: 'tytn-follow-recommendations',
    templateUrl: './follow-recommendations.component.html',
    styleUrls: ['./follow-recommendations.component.scss']
})
export class FollowRecommendationsComponent {
    @Input() posts: ApiPost[];
}

@if (state === 'loading') {
  <app-spinner></app-spinner>
}
@if (state === 'loaded') {
  @if (!featuredVOD) {
    <div
      class="thumbnail" role="img" aria-label="Thumbnail"
      [responsiveBackgroundImage]="production.thumbnail_landscape1"
    ></div>
  }
  @if (featuredVOD) {
    <a
      mat-button
      (click)="clickFeaturedVOD($event)"
      [href]="watchUrl"
      class="thumbnail" role="img" aria-label="Thumbnail"
      [responsiveBackgroundImage]="production.thumbnail_landscape1"
      >
      <mat-icon>play_arrow</mat-icon>
    </a>
  }
  <div class="header">
    <tyt-production-view [showFeedTag]="false" [includeMetadata]="true" [includeItems]="false" [production]="production"></tyt-production-view>
  </div>
  @if (production.full_length_vods.length > 0 || production.full_length_podcasts.length > 0 || production.vod_clips.length > 0 || production.audio_clips.length > 0) {
    @if (!showMedia || !isActivated) {
      <a mat-button href="javascript:;" (click)="handleShowMedia()">
        <mat-icon>play_arrow</mat-icon>
        Show Content
      </a>
    }
    @if (showMedia && isActivated) {
      <mat-tab-group mat-stretch-tabs="false">
        @if (production.full_length_vods.length > 0) {
          <mat-tab label="Episode">
            <tyt-production-view
              [showFeedTag]="false"
              [playInline]="true"
              [playRemote]="true"
              [includeMetadata]="false"
              [includeItems]="true"
              mode="episodes"
              (playClicked)="playInline($event, 'episodes')"
            [production]="production"></tyt-production-view>
          </mat-tab>
        }
        @if (production.vod_clips.length > 0) {
          <mat-tab label="Clips">
            <tyt-production-view
              [showFeedTag]="false"
              [playInline]="true"
              [playRemote]="true"
              [includeMetadata]="false"
              [includeItems]="true"
              mode="clips"
              (playClicked)="playInline($event, 'clips')"
            [production]="production"></tyt-production-view>
          </mat-tab>
        }
        @if (production.full_length_podcasts.length > 0) {
          <mat-tab label="Podcast">
            <tyt-production-view
              [showFeedTag]="false"
              [playInline]="true"
              [playRemote]="true"
              [includeMetadata]="false"
              [includeItems]="true"
              mode="podcasts"
              (playClicked)="playInline($event, 'podcasts')"
            [production]="production"></tyt-production-view>
          </mat-tab>
        }
      </mat-tab-group>
    }
  }
}
@if (state === 'playing') {
  <tyt-media-view
    #mediaView
    [showComments]="false"
    [showSocialButtons]="false"
    [production]="fullProduction"
    [show]="fullProduction.show"
    [item]="item"
    [mode]="mode"
    (playbackRevoked)="deactivate()"
  [user]="user"></tyt-media-view>
}

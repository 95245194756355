import { Component, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiUser } from '@tytapp/api';
import { UserService } from '@tytapp/user';
import { Subject, Subscription } from 'rxjs';
import { FollowsService } from '../follows.service';

@Component({
    selector: 'tyt-follow-button',
    templateUrl: './follow-button.component.html',
    styleUrls: ['./follow-button.component.scss']
})
export class FollowButtonComponent {
    constructor(
        private userService: UserService,
        private followService: FollowsService,
        private router: Router
    ) {
    }

    private _type: string;
    private _id: string;
    private _assumeFollowed: boolean = null;

    @Input()
    followText = 'Follow';

    @Input()
    unfollowText = 'Unfollow';

    @Input()
    get assumeFollowed() {
        return this._assumeFollowed;
    }

    set assumeFollowed(value) {
        this._assumeFollowed = value;
    }

    @Input()
    get type() { return this._type; }
    set type(value) {
        this._type = value;
        clearTimeout(this.resolveStateTimeout);
        this.resolveStateTimeout = setTimeout(() => this.resolveState());
    }

    @Input()
    get id() { return this._id; }
    set id(value) {
        this._id = value;
        clearTimeout(this.resolveStateTimeout);
        this.resolveStateTimeout = setTimeout(() => this.resolveState());
    }

    @Output()
    followed = new Subject<void>();

    @Output()
    unfollowed = new Subject<void>();

    private resolveStateTimeout;
    private fetchedKey: string;
    private subscriptions = new Subscription();
    private user: ApiUser;

    async ngOnInit() {
        this.subscriptions.add(this.userService.userChanged.subscribe(user => {
            this.user = user;
            this.resolveState()
        }));
        this.subscriptions.add(this.followService.events.subscribe(event => {
            if (event.id === this.id && event.type === this.type) {
                this.isFollowed = event.followed;
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    isFollowed = false;

    async resolveState() {
        if (!this.user)
            return;

        if (!this.type || !this.id)
            return;

        let key = `${this.type}/${this.id}`;
        if (this.fetchedKey === key)
            return;

        this.fetchedKey = key;

        if (this._assumeFollowed !== null) {
            this.isFollowed = this._assumeFollowed;
            this._assumeFollowed = null;
            return;
        }

        this.acting = true;
        this.actingLabel = '...';
        try {
            this.isFollowed = await this.followService.isFollowing(this.type, this.id);
        } finally {
            this.acting = false;
        }
    }

    acting = false;
    actingLabel = '...';

    async follow() {
        await this.userService.ready;
        if (!this.userService.user) {
            this.router.navigateByUrl('/signup');
            return;
        }
        if (!this.type || !this.id)
            return;
        if (this.isFollowed || this.acting)
            return;
        this.acting = true;
        this.actingLabel = 'Following...';
        try {
            await this.followService.follow(this.type, this.id);
        } finally {
            this.acting = false;
        }

        this.followed.next();
    }

    async unfollow() {
        await this.userService.ready;
        if (!this.userService.user) {
            this.router.navigateByUrl('/signup');
            return;
        }

        if (!this.type || !this.id)
            return;
        if (!this.isFollowed || this.acting)
            return;
        this.actingLabel = 'Unfollowing...';
        this.acting = true;
        try {
            await this.followService.unfollow(this.type, this.id);
        } finally {
            this.acting = false;
        }

        this.unfollowed.next();
    }
}
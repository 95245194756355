import { Component, HostBinding, Input, Output, Query, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ApiPodcast, ApiPoll, ApiProduction, ApiUser, ApiVOD, PollsApi, ProductionsApi } from '@tytapp/api';
import { ApiSocialProduction } from '@tytapp/api';
import { AudienceType, sleep } from '@tytapp/common';
import { MediaViewComponent, Playback } from '@tytapp/media-playback';
import { UserService } from '@tytapp/user';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'tyt-poll-post',
    templateUrl: './poll-post.component.html',
    styleUrls: ['./poll-post.component.scss']
})
export class PollPostComponent {
    constructor(
        private userService: UserService,
        private pollsApi: PollsApi
    ) {

    }

    ngOnInit() {
        this.userService.userChanged.subscribe(user => {
            this.user = user;
            this.audience = this.userService.audienceType;
        });
        this.state = 'loaded';
    }

    user: ApiUser;
    audience: AudienceType;

    @Input()
    poll: ApiPoll;

    @Input()
    showDescription = true;

    fullPoll: ApiPoll;

    get isPetition() {
        return this.poll.type === 'Petition';
    }

    @Output()
    activated = new Subject<void>();

    @Output()
    deactivated = new Subject<void>();

    state = 'loading';

    @Output()
    validChange = new Subject<boolean>();

    setValid(valid: boolean) {
        this.validChange.next(valid);
    }

    @Input()
    isActivated = false;

    activate() {
        this.isActivated = true;
        this.activated.next();
    }

    deactivate() {
        this.isActivated = false;
        this.state = 'loaded';
        this.deactivated.next();
    }

    async becameVisible() {
        this.fullPoll = await this.pollsApi.getPoll(this.poll.id).toPromise();
    }
}

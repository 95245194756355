<div class="header">
  <a routerLink="/@{{user?.username}}" class="profile-image" [responsiveBackgroundImage]="profileImage" alt="Your Profile icon"></a>
  @if (user) {
    <a routerLink="/@{{user?.username}}" class="username">
      &#64;{{user?.username}}
    </a>
  }
</div>
@if (threaded) {
  <div class="thread-line"></div>
}
<div class="user-content">
  @if (postType === 'comment') {
    <textarea
      #newPostText
      attachmentScraper
      autosize
      [attachments]="attachmentsForComment(post.comment)"
      (attachmentsChange)="post.comment.attachments = $event"
      [(ngModel)]="post.comment.text"
      [placeholder]="prompt"
      maxlength="500"
    rows="10"></textarea>
    <banta-attachments
      [attachments]="attachmentsForComment(post.comment)"
      [editing]="true"
      (remove)="removeAttachment($event)"
    ></banta-attachments>
  }
  @if (postType === 'poll') {
    <textarea
      #newPostText
      autosize
      [(ngModel)]="post.poll.description"
      [placeholder]="post.poll.type === 'Petition' ? 'Describe the petition' : 'Describe the poll'"
    rows="10"></textarea>
    @if (editing) {
      <div class="cannot-edit-message">
        <em>
          @if (post.poll.type === 'Petition') {
            Petitions cannot be edited after posting.
          }
          @if (post.poll.type !== 'Petition') {
            Poll questions/choices cannot be edited after posting.
          }
        </em>
      </div>
      <tyt-poll-post
        style="pointer-events: none; opacity: 0.5"
        [showDescription]="false"
        [poll]="post.poll"
        [isActivated]="isActivated"
        (activated)="activate()"
      ></tyt-poll-post>
    }
    @if (!editing) {
      <tyt-staging-note [shown]="true">
        User-generated polls (ie poll posts) are a preview feature. They will not be enabled in production for
        Nation R1.
      </tyt-staging-note>
      <tyt-poll-editor
        [poll]="post.poll"
        [showDescriptionField]="false"
        [showSlugField]="false"
        [showTitleField]="false"
        [allowUserSelection]="false"
        [allowTopicSelection]="false"
        [allowBackdropSelection]="false"
      ></tyt-poll-editor>
      <a mat-button (click)="addComment()">Remove Poll</a>
    }
  }
  @if (postType === 'petition') {
    <tyt-staging-note [shown]="true">
      User-generated petitions (ie petition posts) are a preview feature. They will not be enabled in production for
      Nation R1.
    </tyt-staging-note>
    <tyt-poll-editor
      [poll]="post.poll"
      [showDescriptionField]="true"
      [showSlugField]="true"
      [showTitleField]="true"
      [allowUserSelection]="false"
      [allowTopicSelection]="false"
      [allowBackdropSelection]="true"
    ></tyt-poll-editor>
    <a mat-button (click)="addComment()">Remove Petition</a>
  }
</div>
<div class="footer">
  <div class="formatting">
    <button mat-icon-button (click)="formatText('bold')" matTooltip="Bold">
      <mat-icon>format_bold</mat-icon>
    </button>
    <button mat-icon-button (click)="formatText('italic')" matTooltip="Italic">
      <mat-icon>format_italic</mat-icon>
    </button>
    <banta-attachment-button
      (addedAttachment)="addedAttachment($event)"
      (attachmentError)="attachmentError($event)"
    ></banta-attachment-button>
    <emoji-selector-button (selected)="insertEmoji($event)" matTooltip="Emoji"></emoji-selector-button>
    @if (enablePolls && !post.poll) {
      <button mat-icon-button (click)="addPoll()" matTooltip="Poll">
        <mat-icon>poll</mat-icon>
      </button>
    }
    @if (enablePetitions && !post.poll) {
      <button mat-icon-button (click)="addPetition()" matTooltip="Petition">
        <mat-icon>campaign</mat-icon>
      </button>
    }
  </div>
  <div class="spacer"></div>
  @if (!commentingCurrentlyDisabled) {
    <div class="topic" [class.empty]="post.topic_id == undefined">
      <mat-form-field>
        <mat-select #topicSelect [placeholder]="post.topic == undefined ? 'Select Topic' : ''" [(ngModel)]="post.topic" floatLabel="never">
          <mat-option [value]="''">No Topic</mat-option>
          @for (topic of topics; track topic) {
            <mat-option [value]="topic">{{topic.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  }

  @if (showCancel) {
    <button mat-button (click)="canceled.next()" class="cancel">
      {{cancelText}}
    </button>
  }

  @if (!commentingCurrentlyDisabled) {
    <button mat-button (click)="submit()" [disabled]="!isValidPost()" class="submit">
      {{actionText}}
    </button>
  }

  @if (supportTestTools) {
    <button id="bypass-post-limit" (click)="bypassRateLimit = true">
      Bypass Post Limit (for integration testing)
    </button>
  }

  @if (commentingCurrentlyDisabled) {
    <button mat-button (click)="whyDisabled()">
      <mat-icon>help</mat-icon>
      Why can't I post?
    </button>
  }
</div>

@if (showDemoNote) {
  <div class="policy-note">
    You are using a demonstration version of TYT Nation, the content you post here is for demonstration purposes
    and will not be carried into the final version of TYT Nation. Content posted here will not be shared with
    members of the audience, and is generally not accessible by external visitors, but be advised the content
    is still on the open Internet. TYT's
    <a target="_blank" href="https://drive.google.com/file/d/1NKx-jnF2Pzh5zGKpXRxWjWvr3Jgq3Osy/view">External
    Communications and Social Media Policy</a> applies to all content you post here.
  </div>
}
@if (!showDemoNote && isStaff) {
  <div class="policy-note">
    As a reminder, as a TYT staff member, you must abide by the
    <a target="_blank" href="https://drive.google.com/file/d/1NKx-jnF2Pzh5zGKpXRxWjWvr3Jgq3Osy/view">External
    Communications and Social Media Policy</a> on all social networks, including TYT Nation.
  </div>
}
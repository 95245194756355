import { Component, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@tytapp/user';
import { Subject, Subscription } from 'rxjs';
import { LikesService } from '../likes.service';
import { LoggerService } from '@tytapp/common';

@Component({
    selector: 'tyt-like-button',
    templateUrl: './like-button.component.html',
    styleUrls: ['./like-button.component.scss']
})
export class LikeButtonComponent {
    private likeService = inject(LikesService);
    private userService = inject(UserService);
    private router = inject(Router);
    private logger = inject(LoggerService);

    private _type: string;
    private _id: string;
    private _assumeLiked: boolean = null;

    @Input()
    get assumeLiked() {
        return this._assumeLiked;
    }

    set assumeLiked(value) {
        this._assumeLiked = value;
    }

    @Input()
    get type() { return this._type; }
    set type(value) {
        this._type = value;
        clearTimeout(this.resolveStateTimeout);
        this.resolveStateTimeout = setTimeout(() => this.resolveState());
    }

    @Input()
    get id() { return this._id; }
    set id(value) {
        this._id = value;
        clearTimeout(this.resolveStateTimeout);
        this.resolveStateTimeout = setTimeout(() => this.resolveState());
    }

    @Output()
    liked = new Subject<void>();

    @Output()
    unliked = new Subject<void>();

    private resolveStateTimeout;
    private fetchedKey: string;
    private subscriptions = new Subscription();

    async ngOnInit() {
        this.subscriptions.add(this.likeService.events.subscribe(event => {
            if (event.id === this.id && event.type === this.type) {
                this.isLiked = event.liked;
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    isLiked = false;

    async resolveState() {
        if (!this.type || !this.id)
            return;

        let key = `${this.type}/${this.id}`;
        if (this.fetchedKey === key)
            return;

        this.fetchedKey = key;

        if (this._assumeLiked !== null) {
            this.isLiked = this._assumeLiked;
            this._assumeLiked = null;
            return;
        }

        this.acting = true;
        try {
            this.isLiked = await this.likeService.isLiked(this.type, this.id);
        } finally {
            this.acting = false;
        }
    }

    acting = false;

    async like() {
        await this.userService.ready;
        if (!this.userService.user) {
            this.router.navigateByUrl('/signup');
            return;
        }

        if (!this.type || !this.id)
            return;
        if (this.isLiked || this.acting)
            return;
        this.acting = true;
        try {
            await this.likeService.like(this.type, this.id);
            this.liked.next();
        } catch (e) {
            this.logger.error(`Error while liking content ${this.type}/${this.id}:`);
            this.logger.error(e);
        } finally {
            this.acting = false;
        }
    }

    async unlike() {
        await this.userService.ready;
        if (!this.userService.user) {
            this.router.navigateByUrl('/signup');
            return;
        }

        if (!this.type || !this.id)
            return;
        if (!this.isLiked || this.acting)
            return;
        this.acting = true;
        try {
            await this.likeService.unlike(this.type, this.id);
            this.unliked.next();
        } catch (e) {
            this.logger.error(`Error while unliking content ${this.type}/${this.id}:`);
            this.logger.error(e);
        } finally {
            this.acting = false;
        }
    }
}
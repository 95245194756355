<div>
  <mat-icon>person_add</mat-icon>
  Recommended Follows
</div>

<div>
  <button role="tab" [attr.aria-selected]="tab == 'tytns'" aria-controls="tytns-panel"  [disabled]="tab == 'tytns'" [class.neutral]="tab != 'tytns'" (click)="tab = 'tytns'">
    <mat-icon>account_circle</mat-icon>
    TYTNs
  </button>
  <button role="tab" [attr.aria-selected]="tab == 'topics'" aria-controls="topics-panel"  [disabled]="tab == 'topics'" [class.neutral]="tab != 'topics'" (click)="tab = 'topics'">
    <mat-icon class="upside-down">tungsten</mat-icon>
    Topics
  </button>
</div>

<div [attr.id]="tab + '-panel'">
  @if (!loaded) {
    <app-spinner></app-spinner>
  }
  @if (loaded) {
    <div class="list">
      @for (item of list | slice:0:4; track item; let index = $index) {
        <div>
          <a class="image" [routerLink]="urlForFollow(item)" [responsiveBackgroundImage]="item.image_url || '/assets/new-avatar.png'">
            <button class="neutral" (click)="remove(index)">
              <mat-icon>close</mat-icon>
            </button>
          </a>
          <div>
            <a
              [routerLink]="urlForFollow(item)"
              [class.username]="item.type === 'User'"
              [class.topic_title]="item.type === 'CMS::Topic'"
            >{{ item.name }}</a>
            <div>{{ item.summary }}</div>
            <tyt-follow-button [type]="item.type"
            [id]="item.slug ?? item.uuid ?? item.id + ''" (followed)="remove(index)"></tyt-follow-button>
          </div>
        </div>
      }
    </div>
  }
</div>

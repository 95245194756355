import { Component, HostBinding, inject } from '@angular/core';
import { UserService } from '@tytapp/user';
import { ApiFollow, FollowApi, UsersApi } from '@tytapp/api';
import { FollowsService } from '@tytapp/social/follows.service';
import { map } from 'rxjs/operators';
import { LoggerService } from '@tytapp/common';


type Tab = 'tytns' | 'topics';

@Component({
    selector: 'tytn-recommended-follows',
    templateUrl: './recommended-follows.component.html',
    styleUrls: [ './recommended-follows.component.scss' ]
})
export class RecommendedFollowsComponent {
    private userService = inject(UserService);
    private followApi = inject(FollowApi);
    private followService = inject(FollowsService);
    private logger = inject(LoggerService);

    tab: Tab = 'tytns';

    loaded = false;

    tytns: ApiFollow[] = [];
    tytnsPage = 1;

    topics: ApiFollow[] = [];
    topicsPage = 1;

    get list() {
        return this[this.tab];
    }

    @HostBinding('class.empty')
    get empty() {
        return this.loaded && this.tytns.length === 0 && this.topics.length === 0;
    }

    remove(index: number) {
        this.list.splice(index, 1);
        if (this.tab === 'tytns' && !this.list.length) {
            this.fetchRecommendedProfiles();
        }
    }

    async fetchRecommendedProfiles() {
        try {
            const users = await this.followApi.getFollowingsForUser('home', 'User', this.tytnsPage++).toPromise();
            const following = await Promise.all(users.map(user => this.followService.isFollowing(user.type, user.uuid)));

            this.tytns.push(
                ...users.filter(
                    (user, index) => !following[index] && this.userService.user.id !== user.id
                )
            );
        } catch (e) {
            this.logger.error(`Failed to fetch recommended profiles:`)
            this.logger.error(e);
        }
    }

    async fetchRecommendedTopics() {
        try {
            const topics = await this.followApi.getFollowingsForUser('home', 'CMS::Topic').toPromise();
            const following = await Promise.all(topics.map(topic => this.followService.isFollowing(topic.type, topic.uuid)));
            this.topics.push(
                ...topics.filter(
                    (user, index) => !following[index] && this.userService.user.id !== user.id
                )
            );
        } catch (e) {
            this.logger.error(`Failed to fetch recommended topics:`)
            this.logger.error(e);
        }
    }

    async ngOnInit() {
        await this.fetchRecommendedProfiles();
        await this.fetchRecommendedTopics();
        this.loaded = true;
    }

    urlForFollow(follow: ApiFollow) {
        if (follow.type === 'User')
            return `/@${follow.slug}`;
        else if (follow.type === 'CMS::Topic')
            return `/topics/${follow.slug}`;
    }
}

import { Component, Input } from '@angular/core';
import { ApiUserSnippet } from '@tytapp/api';

@Component({
    selector: 'tytn-post-byline',
    templateUrl: './post-byline.component.html',
    styleUrls: ['./post-byline.component.scss']
})
export class PostBylineComponent {
    @Input() user: ApiUserSnippet;

    @Input() url: string;

    get tag() {
        if (this.user?.is_vip)
            return 'official';
        return undefined;
    }

    get profileUrl() {
        return this.url ?? this.user ? `/@${this.user.username}` : undefined;
    }
}

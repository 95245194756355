<mat-menu #postActions="matMenu">
  @if (isPostVisible(post)) {
    <button (click)="share()" mat-menu-item>
      <mat-icon>share</mat-icon>
      <span>Share</span>
    </button>
    @if (isSelf) {
      <button (click)="startEditing()" mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span>Edit</span>
      </button>
    }
    @if (isSelf) {
      <button (click)="delete()" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>Delete</span>
      </button>
    }
    @if (!isSelf) {
      <button (click)="report()" mat-menu-item>
        <mat-icon>warning</mat-icon>
        <span>Report</span>
      </button>
    }
    @if (isStaff) {
      <button (click)="hideAsStaff()" mat-menu-item>
        <mat-icon>remove_circle</mat-icon>
        <span>Hide</span>
      </button>
    }
  }
</mat-menu>
@if (!post) {
  <pre>no_post</pre>
}
@if (post) {
  @if (post.parent && showParent !== false) {
    <tyt-post
      class="parent" [post]="post.parent"
      [threaded]="true" [showParent]="false"
    [stemmed]="showParent === 'stemmed'"></tyt-post>
  }
  @if (editing) {
    <tyt-post-form actionText="Save Changes"
      [showCancel]="true" (canceled)="editing = false"
      (edited)="finishEditing($event)" [editing]="true" [post]="post"
      [threaded]="showReplies && hasReplies"
    ></tyt-post-form>
  }
  @if (!editing) {
    <div class="post-container">
      <!-- TAG -->
      @if (shouldShowTagSpace || recommendationText) {
        <div class=".tag">
          @if (isPinned) {
            <div class="tag-row">
              <mat-icon>push_pin</mat-icon>
              <span>Pinned Post</span>
            </div>
          }
          @if (isSponsored) {
            <div class="tag-row">
              <mat-icon>flash_on</mat-icon>
              <span>Sponsored Post</span>
            </div>
          }
          @if (isPromoted) {
            <div class="tag-row">
              <mat-icon>campaign</mat-icon>
              <span>Sponsored Post</span>
            </div>
          }
          @if (recommendationText) {
            <div class="tag-row recommendation-text">
              <mat-icon>highlight</mat-icon>
              <span>{{recommendationText}}</span>
            </div>
          }
        </div>
      }
      <!-- HEADER -->
      <div class="header" [class.as-invisible]="!isPostVisible(post)" [class.stemmed]="stemmed">
        @if (isPostVisible(post)) {
          <a [routerLink]="profileUrl">
            <div class="image" role="img" [responsiveBackgroundImage]="postUserAvatar"
              defaultImage="/assets/new-avatar.png" [attr.aria-label]="post?.user?.display_name + '\'s avatar'"
            class="user-avatar"></div>
          </a>
        }
        @if (!isPostVisible(post)) {
          <div class="image" role="img" [responsiveBackgroundImage]="'/assets/new-avatar.png'" class="user-avatar"></div>
        }
        <div class="header-content">
          @if (headerFormat === 'as-invisible') {
            @if (post.deleted_at) {
              <em>Post deleted by user</em>
            }
            @if (!post.deleted_at && post.hidden) {
              <em>Post removed</em>
            }
          }
          @if (headerFormat === 'as-top-level-reply') {
            <div>
              <tytn-post-byline [user]="post.user" [url]="profileUrl"></tytn-post-byline>
              replied
              @if (post.parent && !isPostVisible(post.parent)) {
                to a deleted post
              }
              <tyt-timestamp [value]="post.date"></tyt-timestamp>
            </div>
          }
          @if (headerFormat === 'as-reply') {
            <div>
              <tytn-post-byline [user]="post.user" [url]="profileUrl"></tytn-post-byline>
              <tyt-timestamp [value]="post.date"></tyt-timestamp>
            </div>
            <div class="muted">
              {{post.user.followers_count | shortNumber}} followers
            </div>
          }
          @if (headerFormat === 'as-post') {
            <span>
              @if (!stemmed) {
                Posted by
              }
              <tytn-post-byline [user]="post.user" [url]="profileUrl"></tytn-post-byline>
              @if (stemmed) {
                posted
              }
              <tyt-timestamp [value]="post.date"></tyt-timestamp>
              @if (getContextLink(post)) {
                <span>
                  on <a [routerLink]="getContextLink(post).url">{{getContextLink(post).text}}</a>
                </span>
              }
            </span>
          }
          @if (stemmed && isPostVisible(post)) {
            <a [routerLink]="postUrl" mat-button>
              View Post
            </a>
          }
          <!-- CONTENT LABELS -->
          @if (!stemmed) {
            @if (post.content_label === 'misinformation') {
              <span
                class="misinformation content-label"
                [matTooltip]="post.content_label_context ?? 'This post contains information we\'ve found to be false or misleading'"
                >
                Misinformation
              </span>
            }
          }
        </div>
        @if (quickAction === 'like') {
          <tyt-like-button class="compact quick-action" [type]="post.subject_type"
            [id]="post.subject_id+''" (liked)="adjustCounter('likes_count', +1)"
            (unliked)="adjustCounter('likes_count', -1)">
            {{ post.likes_count | shortNumber }}
          </tyt-like-button>
        }
        @if (quickAction === 'reply') {
          <a mat-button routerLink="/@{{post.user.username}}/posts/{{post.id}}"
            class="quick-action">
            <mat-icon>chat_bubble</mat-icon>
            {{post.comment_count | shortNumber }}
          </a>
        }
      </div>
      <!-- BODY -->
      @if (isPostVisible(post) && !stemmed) {
        <div [class.highlighted]="isHighlighted" class="body">
          <!-- COMMENT -->
          @if (post.comment) {
            <div class="comment tytn-user-post-text cms-content" linksToRouter
              [innerHtml]="post.comment.text | hashtagLinker: post.comment['hash_tags'] | mentionLinker: (post.comment.data?.['mentionLinks'] || []) | markdownToHtml: 'user' | trustHtml">
            </div>
            <banta-attachments [attachments]="attachmentsForComment(post.comment)"></banta-attachments>
          }
          <!-- ARTICLE -->
          @if (post.article) {
            <div class="article-content">
              <a target="_blank" [routerLink]="articleUrl(post.article)" class="thumbnail" role="img"
              aria-label="Thumbnail" [responsiveBackgroundImage]="post.article.lead_image"></a>
              <a target="_blank" [routerLink]="articleUrl(post.article)" class="info">
                <div class="title">{{post.article.feed_headline || post.article.title}}</div>
                <div class="byline">
                  {{bylineForArticle(post.article)}}
                </div>
                <summary>
                  {{ post.article.feed_summary }}
                </summary>
                <tyt-content-badge type="article" [unlocked]="false" [premium]="post.article.members_only"
                [topic]="post.article.topics[0]"></tyt-content-badge>
              </a>
            </div>
          }
          <!-- PRODUCTION -->
          @if (post.production) {
            <tyt-production-post [production]="post.production" [isActivated]="isActivated"
            (activated)="activate()"></tyt-production-post>
          }
          <!-- POLL -->
          @if (post.poll) {
            <tyt-poll-post [poll]="post.poll" [isActivated]="isActivated" (activated)="activate()"></tyt-poll-post>
          }
        </div>
      }
      <!-- FOOTER -->
      @if (!isPostVisible(post) || stemmed) {
        <div class="stemmed-spacer"></div>
      }
      @if (!shouldShowTagSpace && !compact && isPostVisible(post) && !stemmed) {
        <div class="footer">
          <a mat-button routerLink="/@{{post.user.username}}/posts/{{post.id}}" class="action">
            <mat-icon>chat_bubble</mat-icon>
            {{post.comment_count | shortNumber}}
          </a>
          @if (repostEnabled) {
            <a mat-button class="action">
              <mat-icon>repeat</mat-icon>
              {{post['repost_count'] | shortNumber}}
            </a>
          }
          @if (!isUserHome && showLike) {
            <tyt-like-button [type]="post.subject_type" [id]="post.subject_id + ''"
              (liked)="adjustCounter('likes_count', +1)" (unliked)="adjustCounter('likes_count', -1)">
              {{post.likes_count | shortNumber}}
            </tyt-like-button>
          }
          @if (topicTitle) {
            <a [href]="topicLink" class="action topic_title">
              {{topicTitle}}
            </a>
          }
          <div class="action">
            <button mat-icon-button [matMenuTriggerFor]="postActions">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </div>
      }
      <div class="thread-line"></div>
    </div>
    @if (showReply) {
      <tyt-post-form class="reply" [prompt]="replyPrompt" actionText="Reply" [parent]="post"
      (created)="replyCreated.next($event)"></tyt-post-form>
    }
  }
  @if (showReplies && hasReplies) {
    <div class="replies">
      @for (reply of post.replies; track reply) {
        <tyt-post [showParent]="false" [threaded]="true" [post]="reply"></tyt-post>
        <mat-divider></mat-divider>
      }
    </div>
  }
}

<ng-template cdkPortal #selectorPanelTemplate="cdkPortal">
  <div class="share-container">
    <a class="close-btn" href="javascript:;" (click)="overlayRef.detach()"><mat-icon>close</mat-icon></a>
    @if (!disableSharing) {
      <tyt-social-buttons [url]="url" [title]="shareTitle"></tyt-social-buttons>
    }
    @if (disableSharing) {
      <div class="no-sharing-message">
        Sharing to social media is disabled on this version of TYT.com. Please do not share URLs to this website
        externally
      </div>
    }
  </div>
</ng-template>

import { Component, HostBinding, inject, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { ApiPodcast, ApiProduction, ApiUser, ApiVOD, ProductionsApi } from '@tytapp/api';
import { ApiSocialProduction } from '@tytapp/api';
import { AudienceType, LoggerService, sleep } from '@tytapp/common';
import { MediaViewComponent, Playback } from '@tytapp/media-playback';
import { UserService } from '@tytapp/user';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProductionsService } from '@tytapp/common-ui';

@Component({
    selector: 'tyt-production-post',
    templateUrl: './production-post.component.html',
    styleUrls: ['./production-post.component.scss']
})
export class ProductionPostComponent {
    private productionsApi = inject(ProductionsApi);
    private userService = inject(UserService);
    private playback = inject(Playback);
    private productionsService = inject(ProductionsService);
    private logger = inject(LoggerService);

    ngOnInit() {
        this.userService.userChanged.subscribe(user => {
            this.user = user;
            this.audience = this.userService.audienceType;
            this.pickFeaturedVOD();
        });
        this.state = 'loaded';
    }

    user: ApiUser;
    audience: AudienceType;
    featuredVOD: ApiVOD;

    featuredPlaylist: 'episodes' | 'clips';

    @ViewChildren('mediaView')
    mediaViewQuery: QueryList<MediaViewComponent>;

    pickFeaturedVOD() {
        if (!this.production)
            return;

        if (this.user?.entitled) {
            this.featuredVOD = this.production.full_length_vods[0];
            this.featuredPlaylist = 'episodes';
        } else {
            this.featuredVOD = this.production.vod_clips[0];
            this.featuredPlaylist = 'clips';
        }
    }

    get mediaView() {
        return this.mediaViewQuery.first;
    }

    async waitForMediaView() {
        if (this.mediaView)
            return this.mediaView;

        await this.mediaViewQuery.changes.pipe(take(1)).toPromise();
        return this.mediaView;
    }

    @Input()
    production: ApiSocialProduction;
    item: ApiVOD | ApiPodcast;

    @Output()
    activated = new Subject<void>();

    @Output()
    deactivated = new Subject<void>();

    playing = false;
    mode: 'clips' | 'episodes' | 'podcasts' | 'audio-stories';

    fullProduction: ApiProduction;

    state = 'loading';

    @HostBinding('class.playing')
    get isPlaying() { return this.state === 'playing'; }

    @Input()
    isActivated = false;

    activate() {
        this.isActivated = true;
        this.activated.next();
    }

    deactivate() {
        this.isActivated = false;
        this.state = 'loaded';
        this.deactivated.next();
    }

    showMedia = false;

    async clickFeaturedVOD(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        await this.playInline(this.featuredVOD, this.featuredPlaylist);
    }

    async playInline(item: ApiVOD | ApiPodcast, mode: 'clips' | 'episodes' | 'podcasts' | 'audio-stories') {
        this.state = 'loading';
        try {
            this.activate();

            if (!this.fullProduction)
                this.fullProduction = await this.productionsApi.get(this.production.id).toPromise();

            let allItems: (ApiVOD | ApiPodcast)[] = [].concat(
                this.fullProduction.vod_clips,
                this.fullProduction.full_length_vods,
                this.fullProduction.audio_clips,
                this.fullProduction.full_length_podcasts,
            );

            this.item = allItems.find(x => x.id === item.id);
            this.playing = true;
            this.mode = mode;
            this.state = 'playing';
            let mediaView = await this.waitForMediaView();
            let player = await mediaView.waitForPlayer();
            player.production = this.fullProduction;

            // First, revoke playback to trigger other production posts to return to "loaded"
            // state.
            this.playback.revokePlayback();

            // Wait a bit here to allow the other video post to cause some scroll shift
            await sleep(100);

            player.playbackElement.scrollIntoView({
                block: 'center'
            })
            await sleep(400);

            player.autoplay = true;
            await player.playItem(this.item);
        } catch (e) {
            this.state = 'loaded';
            this.logger.error(`Failed to play content:`);
            this.logger.error(e);

            alert(`Uh oh! We're having trouble playing this right now. Please try again later`);
        }
    }

    handleShowMedia() {
        this.showMedia = true;
        this.activate();
    }

    get watchUrl() {
        return this.productionsService.getWatchUrlSync(this.production, this.featuredPlaylist, this.featuredVOD);
    }
}

import { Component, Input } from '@angular/core';
import { ApiPoll } from '@tytapp/api';


type Tab = 'tytns' | 'topics';

@Component({
    selector: 'tytn-featured-poll',
    templateUrl: './featured-poll.component.html',
    styleUrls: [ './featured-poll.component.scss' ]
})
export class FeaturedPollComponent {
    @Input()
    poll: ApiPoll;

    get type() {
        return this.poll?.type === 'Petition' ? 'Petition' : 'Poll';
    }

    get route() {
        return this.poll?.type === 'Petition' ? 'petitions' : 'polls';
    }
}

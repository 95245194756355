import { Component, Input } from '@angular/core';

@Component({
    selector: 'tyt-follow-icon',
    template: `
        <mat-icon>{{icon}}</mat-icon>
    `,
    styles: [`
        :host {
            display: inline-flex;
            vertical-align: middle;
        }
    `]
})
export class FollowIconComponent {
    @Input() type: string;
    @Input() followed = false;
    @Input() followedIcon: string = 'auto';
    @Input() unfollowedIcon: string = 'auto';
    @Input() action = false;

    get icon() {
        return this.followed ? this.actualFollowedIcon : this.actualUnfollowedIcon;
    }

    get actualFollowedIcon() {
        if (this.followedIcon === 'auto') {
            switch (this.type) {
                case 'User':
                    if (this.action)
                        return 'person_add';
                    else
                        return 'check'
                default:
                    return 'star';
            }
        }

        return this.followedIcon;
    }

    get actualUnfollowedIcon() {
        if (this.unfollowedIcon === 'auto') {
            switch (this.type) {
                case 'User':
                    if (this.action)
                        return 'person_remove';
                    else
                        return 'close';
                default:
                    return 'star_border';
            }
        }

        return this.unfollowedIcon;
    }
}
@if (state === 'loading') {
  <app-spinner></app-spinner>
}
@if (state === 'loaded') {
  <tyt-intersection-observer (firstBecameVisible)="becameVisible()">
    @if (poll.description && showDescription && !isPetition) {
      <div class="tytn-user-post-text cms-content" [innerHTML]="poll.description | markdownToHtml | trustHtml"></div>
      <mat-divider></mat-divider>
    }
    <tyt-poll
      [poll]="fullPoll || poll"
      [showSocialButtons]="false"
      [allowEdit]="false"
      [showDescription]="false"
    ></tyt-poll>
  </tyt-intersection-observer>
}
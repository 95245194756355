import { Component, ElementRef, HostBinding, Input, Output, ViewChild, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ChatMessageAttachment } from '@banta/common';
import { ApiComment, ApiCommentAttachment, ApiPatchUsersCurrentPosts, ApiPost, ApiPostUsersCurrentPosts, ApiTopic, ApiUser, PostsApi } from '@tytapp/api';
import { AppConfig, LoggerService, Shell } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { PollsService } from '@tytapp/polls';
import { CommentingDisabledComponent } from '@tytapp/social/commenting-disabled.component';
import { TopicsService } from '@tytapp/topics';
import { UserService } from '@tytapp/user';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'tyt-post-form',
    templateUrl: './post-form.component.html',
    styleUrls: ['./post-form.component.scss']
})
export class PostFormComponent {
    private postsApi = inject(PostsApi);
    private userService = inject(UserService);
    private topicsService = inject(TopicsService);
    private pollsService = inject(PollsService);
    private router = inject(Router);
    private shell = inject(Shell);
    private appConfig = inject(AppConfig);
    private logger = inject(LoggerService);

    @Output() created = new Subject<ApiPost>();
    @Input() prompt = `What's on your mind?`;
    @ViewChild('newPostText') postTextArea: ElementRef<HTMLTextAreaElement>;
    @Input() actionText = 'Post';
    @Input() showCancel = false;
    @Input() cancelText = 'Cancel';
    @Input() editing = false;

    @Output() canceled = new Subject<void>();
    @Output() edited = new Subject<ApiPost>();

    subscriptions = new Subscription();
    user: ApiUser;

    @Input() parent: ApiPost;

    @HostBinding('class.threaded')
    @Input() threaded = false;
    pollPostFeatureEnabled = false;
    petitionPostFeatureEnabled = false;

    get enablePolls() {
        return this.pollPostFeatureEnabled && this.user?.staff;
    }

    get enablePetitions() {
        return this.petitionPostFeatureEnabled && this.user?.staff;
    }

    get profileImage() {
        return this.user?.profile?.avatar ?? 'assets/new-avatar.png';
    }

    get profile() {
        return this.user.profile;
    }

    isActivated = false;
    activate() {
        // TODO
    }

    async ngOnInit() {
        this.topics = await this.topicsService.all();
        if (this._post?.topic)
            this._post.topic = this.topics.find(x => x.id === this._post.topic.id);

        this.pollPostFeatureEnabled = await this.shell.hasFeature('apps.web.enable_poll_posts');
        this.petitionPostFeatureEnabled = await this.shell.hasFeature('apps.web.enable_petition_posts');

        this.subscriptions.add(
            this.userService.userChanged.subscribe(user => this.user = user)
        );
    }

    private _postType: 'comment' | 'poll' | 'petition' = null;
    get postType() {
        if (!this._postType && this.post) {
            if (this.post.comment)
                this._postType = 'comment';
            else if (this.post.poll)
                this._postType = 'poll';
        }
        return this._postType;
    }

    set postType(type) {
        this._postType = type;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    attachmentsForComment(comment: ApiComment) {
        return <ChatMessageAttachment[]>comment.attachments;
    }

    private _post: ApiPostUsersCurrentPosts = {
        topic_id: null,
        comment: {
            text: '',
            attachments: []
        }
    };

    @Input()
    get post() {
        return this._post;
    }

    set post(value) {
        this._post = value;
        if (this.topics && this._post?.topic)
            this._post.topic = this.topics.find(x => x.id === this._post.topic.id);
    }

    topics: ApiTopic[];

    formatText(action: 'bold' | 'italic') {
        const formatChars = action === 'bold' ? '**' : '_';
        const selectionStart = this.postTextArea.nativeElement.selectionStart;
        const selectionEnd = this.postTextArea.nativeElement.selectionEnd;

        if (selectionStart === selectionEnd) {
            this.post.comment.text += `${formatChars}${action} text here${formatChars}`;
        } else {
            this.post.comment.text = this.post.comment.text.slice(0, selectionStart)
                + formatChars
                + this.post.comment.text.slice(selectionStart, selectionEnd)
                + formatChars
                + this.post.comment.text.slice(selectionEnd)
                ;
        }

    }

    insertEmoji(text: string) {
        const selectionStart = this.postTextArea.nativeElement.selectionStart;
        const selectionEnd = this.postTextArea.nativeElement.selectionEnd;

        this.post.comment.text = this.post.comment.text.slice(0, selectionStart)
            + text
            + this.post.comment.text.slice(selectionEnd)
            ;
    }

    async submit() {
        if (!this.user) {
            this.router.navigateByUrl(`/signup`);
            return;
        }

        if (!this.isValidPost()) {
            alert(`This post is invalid. Please check for errors and try again.`);
            return;
        }

        if (this.editing) {
            let editedPost = JSON.parse(JSON.stringify(<ApiPatchUsersCurrentPosts & { id: number; }>this.post));
            let id = editedPost.id;
            delete editedPost.id;
            try {
                let newPost = await this.postsApi.editPost(id, editedPost).toPromise();
                this.edited.next(newPost);
            } catch (e) {
                if (e.json)
                    e = e.json();

                this.logger.error(`Failed to edit post:`);
                this.logger.error(e);
                alert(`Failed to edit post: ${e.message || e.error || 'Unknown error'}`);
            }
            return;
        }


        let post: ApiPost;

        // Convert '' to null
        if (!this.post.topic)
            this.post.topic = null;

        if (this.parent)
            this.post.parent_id = this.parent.id;

        if (this.bypassRateLimit)
            this.post.bypass_rate_limit = true;

        this.logger.inspect('Creating post:');
        this.logger.inspect(this.post);

        try {
            post = await this.postsApi.createPost(this.post).toPromise();
        } catch (e) {
            if (e.json)
                e = e.json();

            if (e.code === 'not_invited') {
                if (this.user.entitled) {
                    alert(
                        `TYT Nation is currently in a closed beta. `
                        + `TYT members in good standing already have access, `
                        + `but it looks like there may be an issue with your membership.`
                        + ` Please check your Membership status in Settings, if you `
                        + `continue to have issues, contact us at support@tytnetwork.com`
                    );
                } else {
                    alert(
                        `TYT Nation is currently in a closed beta. Please sign up for the `
                        + `waiting list to get access. TYT members in good standing already`
                        + ` have access, and do not need to sign up for the waiting list.`
                    );
                }

                return;
            } else if (e.code === 'commenting_disabled') {
                alert(`You are not currently allowed to post.`);
            }

            this.logger.error(`Failed to create post:`);
            this.logger.error(e);

            alert(
                `Failed to create post: ${e.message || e.error || 'Unknown error'} -- `
                + `Please try again later, if this issue persists please contact support@tytnetwork.com`
            );
            return;
        }

        this.created.next(post);

        this.post.poll = null;
        this.post.comment = { text: '', attachments: [] };
        this.post.topic = undefined;
        this.postType = null;
    }

    removeAttachment(attachment: ChatMessageAttachment) {
        let index = this.post.comment.attachments.indexOf(attachment);
        if (index >= 0)
            this.post.comment.attachments.splice(index, 1);
    }

    addedAttachment(attachment: ChatMessageAttachment) {
        this.post.comment.attachments.push(attachment);
    }

    attachmentError(attachment: ChatMessageAttachment) {
        setTimeout(() => {
            this.post.comment.attachments = this.post.comment.attachments.filter(x => x !== attachment);
        }, 3000);
    }

    addPoll() {
        this.post.poll = {
            type: 'ChooseOnePoll',
            questions: [
                {
                    body: '',
                    choices: []
                }
            ]
        };
        this.post.poll.description = this.post.comment.text;
        this.post.comment = undefined;
        this.postType = 'poll';
    }

    addPetition() {
        this.post.poll = {
            type: 'Petition',
            questions: [
                {
                    body: '',
                    choices: []
                }
            ]
        };
        this.post.poll.description = this.post.comment.text;
        this.post.comment = undefined;
        this.postType = 'petition';
    }

    addComment() {
        this.post.comment = {
            text: this.post.poll?.description,
            attachments: []
        };
        this.post.poll = undefined;
        this.postType = 'comment';
    }

    pollValid = false;

    isValidAttachment(attachment: ApiCommentAttachment) {
        if (!attachment)
            return false;

        if (attachment['transientState']?.loading || attachment['transientState']?.error || attachment['transientState']?.uploading)
            return false;
        return true;
    }

    isValidPost() {
        if (this.postType === 'comment') {
            if (!this.post.comment)
                return false;
            if ((this.post.comment.attachments as ApiCommentAttachment[]).some(x => !this.isValidAttachment(x)))
                return false;
            return !!(this.post.comment.text?.trim()) || this.post.comment.attachments.length > 0;
        } else if (this.postType === 'poll') {
            return this.pollsService.isValidForPublishing(this.post.poll, ['title']);
        } else if (this.postType === 'petition') {
            return this.post.poll.title
                && this.post.poll.questions[0].body
                && this.post.poll.description;
        }
    }

    get commentingCurrentlyDisabled() {
        if (!this.user?.disable_commenting)
            return false;

        if (this.user?.disable_commenting_until)
            return new Date(this.user?.disable_commenting_until).getTime() > Date.now();

        return true;
    }

    get commentingDisabled() {
        return this.user?.disable_commenting;
    }

    get commentingDisabledUntil() {
        return this.user?.disable_commenting_until;
    }

    get actualActionText() {
        if (this.commentingDisabled) {
            return 'Not Allowed';
        }

        return this.actionText;
    }

    whyDisabled() {
        this.shell.showDialog(CommentingDisabledComponent);
    }

    get showDemoNote() {
        return this.appConfig.environment !== 'production';
    }

    get isStaff() {
        if (!this.user)
            return false;

        return this.user.staff;
    }

    get supportTestTools() {
        return environment.showDevTools;
    }

    bypassRateLimit = false;
}
import { Pipe } from '@angular/core';


@Pipe({
    name: 'hashtagLinker'
})
export class HashtagLinkerPipe {
    classProp = 'class="hashtag"';

    transform(value: string, hashtags): string {
        if (!value) return '';

        const matches = new Set(
            (hashtags || [])
                .map(
                    hashtag => hashtag.name
                )
                .filter(Boolean)
        );

        if (!matches.size) return value;

        const regex = new RegExp(`#(${ [...matches].join('|') })`, 'gi');

        return  value.replace(regex, this.anchor());
    }

    anchor() {
        return `<a ${ this.classProp } href="/hashtag/$1">$&</a>`;
    }
}
